import UIkit from "uikit";
import Icons from 'uikit/dist/js/uikit-icons';
import "./cookie";

document.addEventListener("DOMContentLoaded", function () {
    const lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
    let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                let lazyImage = entry.target;
                lazyImage.src = lazyImage.dataset.src;
                // lazyImage.srcset = lazyImage.dataset.srcset;
                lazyImage.classList.remove("lazy");
                lazyImageObserver.unobserve(lazyImage);
            }
        });
    });
    lazyImages.forEach(function (lazyImage) {
        lazyImageObserver.observe(lazyImage);
    });
});

console.log("ff")

UIkit.use(Icons);


// components can be called from the imported UIkit reference
// UIkit.notification('Hello world.');

